body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #1e1e1e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Courier New', monospace;
}

.terminal-window {
  width: 80vw;  /* 80% of the viewport width */
  height: 80vh; /* 80% of the viewport height */
  max-width: 1000px; /* Maximum width */
  max-height: 800px; /* Maximum height */
  background-color: #2d2d2d;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0,0,0,0.5);
  overflow: hidden;
}

.terminal-header {
  background-color: #3c3c3c;
  padding: 15px;
  display: flex;
}

.button {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 8px;
}

.red { background-color: #ff5f56; }
.yellow { background-color: #ffbd2e; }
.green { background-color: #27c93f; }

.terminal-body {
  padding: 30px;
  padding-top: 50px;  /* Increased top padding */
  padding-bottom: 70px;  /* Increased bottom padding */
  height: calc(100% - 40px); /* Adjust based on your terminal header height */
  overflow-y: auto;
  color: #f0f0f0;
  font-size: 16px; /* Increased font size */
  line-height: 1.6; /* Improved line spacing */
}

.input-line {
  display: flex;
  margin-top: 15px;
}

.prompt {
  color: #27c93f;
  margin-right: 10px;
}

#user-input {
  background: none;
  border: none;
  color: #f0f0f0;
  font-family: inherit;
  font-size: 16px; /* Match the terminal body font size */
  flex-grow: 1;
  outline: none;
}

.output-line {
  margin-bottom: 10px;
}